import React from "react";
import AdminLayout from "../../components/admin/layout";
import LoginFormComponent from "../../components/admin/login/wrapper";

const LoginPage = () => {
    return (
        <AdminLayout>
            <LoginFormComponent />
        </AdminLayout>
    );
}

export default LoginPage