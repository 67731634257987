import { AdminClient } from "../admin-client";
import { useFormik } from "formik";
import { useState } from "react";

export const useLoginForm = ({ onLoginSuccess, onLoginFailed }) => {
    const adminClient = AdminClient();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        onSubmit: async (values) => {
            try {
                const loginSuccessful = await adminClient.login({
                    username: values.username,
                    password: values.password
                });
                if(loginSuccessful) {
                    onLoginSuccess();
                } else {
                    onLoginFailed();
                }
            } catch (error) {
                onLoginFailed();
            }
        },
    });

    return {
        formik
    }
}