import { Button } from "@material-ui/core"
import TextInput from "../../forms/text-input"
import React, { useState } from "react";
import LoginForm from "./loginForm";
import { AdminClient } from "../admin-client";
import { useLoginForm } from "./useLoginForm.hooks";
import LoadingOverlay from "../../overlay";
import { navigate } from "gatsby";
import { FormikProvider } from "formik";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    width: 100vh;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const LoginFormComponent = () => {
    const [loginFailed, setLoginFailed] = useState(false);

    const { formik } = useLoginForm({
        onLoginFailed: () => setLoginFailed(true),
        onLoginSuccess: () => navigate("/admin")
    });

    return (
        <Container>
            <FormikProvider value={formik}>
                <LoadingOverlay open={formik.isSubmitting} />
                { loginFailed && <p>Błędny login lub hasło</p>}
                <LoginForm formik={formik} />
            </FormikProvider>
        </Container>
    )
};

export default LoginFormComponent;