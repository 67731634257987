import {callApi} from "../api"

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export const AdminClient = () => ({
    login: async ({ username, password }) => {
        let formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        const response = await callApi(`/api/admin/login/process`, {
            method: "POST",
            body: formData,
            credentials: 'include'
        });

        if (response.status === 200) {
            return true;
        } else if (response.status == 401) {
            return false;
        }
    },
    readPricing: async () => {
        const response = await callApi(`/api/admin/pricingconfig`, {
            method: "GET",
            headers: headers,
            credentials: 'include'
        });

        if (response.status === 200) {
            return await response.json();
        } else if (response.status == 401) {
            throw new NotAuthorizedError();
        } else {
            throw new Error("Could not read pricing");
        }
    },
    updatePricing: async ({newPricing}) => {
        const response = await callApi(`/api/admin/pricingconfig`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(newPricing),
            credentials: 'include'
        });

        if (response.status === 200) {
            return await response.json();
        } else if (response.status == 401) {
            throw new NotAuthorizedError();
        } else {
            throw new Error("Could not read pricing");
        }
    },
    estimatePrice: async ({railguardType, selectedValues, newPricingConfig}) => {
        const urlParams = Array.from(selectedValues.entries())
                .map(([key, value]) => `${key}=${value}`)
                .join("&");

        const response = await callApi(`/api/admin/pricing/${railguardType}?${urlParams}`, {
            method: "POST",
            headers: headers,
            body: newPricingConfig,
            credentials: 'include'
        });

        if (response.status === 200) {
            return await response.json();
        } else if (response.status == 401) {
            throw new NotAuthorizedError();
        } else {
            throw new Error("Could not read pricing");
        }
    },
});

export class NotAuthorizedError extends Error {

}