import { Button, Grid } from "@material-ui/core"
import TextInput from "../../forms/text-input"
import React from "react";

const LoginForm = ({ formik }) => {
    const onSubmit = (event) => {
        event.preventDefault();
        formik.submitForm();
    }

    return (
        <>
            <h1>Panel administracyjny</h1>
            <form onSubmit={onSubmit}>
                <Grid container spacing={1} columns={1} justify={"center"} direction={"column"}>
                    <Grid item>
                        <TextInput
                            label="Użytkownik"
                            id="username"
                            name="username"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Grid>
                    <Grid item>
                        <TextInput
                            label="Hasło"
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Grid>
                    <Grid item>
                        <Button type="submit">Zaloguj</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
};

export default LoginForm;