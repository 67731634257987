import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import React from "react"
import styled from "styled-components";
import GlobalStyle from "../global-style";

const StyledContainer = styled(Container)`
  && {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

const theme = createTheme({
    palette: {
        primary: {
            main: '#343a40',
        },
        secondary: {
            main: '#ffc107',
        }
    },
});

const AdminLayout = ({ children }) => {
    return (
        <>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
            <StyledContainer>
                {children}
            </StyledContainer>
        </ThemeProvider>
        </>
    )
}

export default AdminLayout